<template>
  <div class="yt-main" style="padding-top: 0">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>岗位管理</el-breadcrumb-item>
        <el-breadcrumb-item to="/manage/post/match">岗位匹配</el-breadcrumb-item>
        <el-breadcrumb-item>展示详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container yt-container-flex" style="height: 100%">
      <div class="yt-content">
        <div class="match-name">{{ this.postName }}</div>
        <div class="yt-header-toolbar">
          <div>
            <p>颜色从左到右代表要求比例从低到高</p>
          </div>
          <div class="color-box" v-for="(item, index) in colorBox" :key="index" :style="{ backgroundColor: item }"></div>
        </div>
        <el-table
          ref="table"
          :data="postSkillConfigVOS"
          :span-method="arraySpanMethod"
          v-loading="skillLoading"
          element-loading-text="加载中"
          height="calc(100% - 27px)"
          class="yt-table"
        >
          <el-table-column prop="mainSkillName" label="主技能" class-name="main-skill"> </el-table-column>
          <el-table-column prop="subSkillName" label="子技能"> </el-table-column>
          <el-table-column
            v-for="(item, index) in departments"
            :label="item.name"
            :class-name="index === departments.length - 1 ? 'last-column' : ''"
            :key="item.departmentId"
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row[item.departmentId] !== undefined && scope.row[item.departmentId].rateName !== null"
                :style="{ color: colorBox[scope.row[item.departmentId].level] }"
              >
                {{ scope.row[item.departmentId].rateName }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import detail from '@/api/postDepartmentMatch'

export default {
  name: 'PostMatchDetail',
  components: { YTIcon },
  data() {
    return {
      colorBox: ['#134E60', '#085B43', '#6E949F', '#23C2AD', '#71C1CE', '#1CBB74', '#62B19B', '#9EE32C', '#F4AC49', '#F75459'],
      postSkillConfigVOS: [],
      departments: [],
      skillLoading: false
    }
  },
  created() {
    let res = this.$handleParams('postMatchDetailData')
    this.postId = res.postId
    this.postName = res.postName
    this.getDetail()
  },
  computed: {
    groupNum() {
      //获取分组数据
      return new Set(this.postSkillConfigVOS.map(item => item.mainSkillName))
    }
  },
  methods: {
    getDetail() {
      this.skillLoading = true
      detail
        .getConfigDetail(this.postId)
        .then(res => {
          this.postSkillConfigVOS = res.res.postSkillConfigVOS.map(item => {
            res.res.departments.forEach(department => {
              item[department.departmentId] = item.departmentConfigVOS.filter(data => {
                return data.departmentId === department.departmentId
              })[0]
            })
            return item
          })
          this.departments = res.res.departments
        })
        .finally(() => {
          this.skillLoading = false
        })
    },
    //table合并标签
    nameGroup(mainSkillName) {
      // 获取相同名称的数量
      return this.postSkillConfigVOS.filter(item => item.mainSkillName === mainSkillName).length
    },
    nameLen(mainSkillName) {
      //根据名称获取名称第一个数据在全部数据中的偏移位置
      const tmp = Array.from(this.groupNum) //tmp每条目标数据 mainSkillName
      const index = tmp.indexOf(mainSkillName) //该名称在全名称中的偏移位置 index对应名称的下标
      let len = 0 //对应每个名称的行数
      for (let i = 0; i < index; i++) {
        len += this.nameGroup(tmp[i])
      }
      return len
    },
    arraySpanMethod(data) {
      //对于表格数据进行分组合并操作，UI组件回调函数
      const { row, rowIndex, columnIndex } = data
      if (columnIndex === 0) {
        //名称列 合并展示区
        const len = this.nameGroup(row.mainSkillName)
        const lenName = this.nameLen(row.mainSkillName)
        if (rowIndex === lenName) {
          //该名称在首位名称行
          return {
            rowspan: len,
            colspan: 1
          }
        } else
          return {
            //该名称不在首位名称行
            rowspan: 0,
            colspan: 0
          }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.yt-content {
  width: 100%;
  padding: 10px 20px;
  .match-name {
    float: left;
    font-weight: bold;
    margin-left: 11px;
    font-size: 14px;
  }
  .match-name::before {
    content: '';
    width: 4px;
    height: 14px;
    border-radius: 2px;
    margin-left: -10px;
    margin-top: 3px;
    position: absolute;
    background: #438bff;
  }
  .yt-header-toolbar {
    font-size: 12px;
    justify-content: flex-end;
    height: 17px;
    margin-right: 0;
    margin-bottom: 8px;
  }
}
.yt-table {
  border-bottom: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  ::v-deep.el-table__header-wrapper {
    border-radius: 0;
  }
  ::v-deep.el-table__header {
    thead {
      th {
        background-color: #f0f0f0 !important;
      }
    }
  }
}
.color-box {
  margin-left: 6px;
  width: 16px;
  height: 16px;
}
::v-deep .main-skill {
  border-right: 1px solid #f0f0f0;
}
::v-deep .last-column {
  border-right: 1px solid #f0f0f0;
}
</style>
