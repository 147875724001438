import config from '../config/config'
import { axios } from './index'

const postDepartmentMatchUrl = config.baseUrl + '/knowledge/api/v1/post/department/match'

export default {
  //分页获取部门岗位配置信息
  getConfigListByPostId(payload, page, size) {
    return axios.get(`${postDepartmentMatchUrl}/config/list?postId=${payload}&pageNum=${page}&pageSize=${size}`)
  },
  //获取部门岗位匹配信息
  getDetailByDepartmentId(payload) {
    return axios.get(`${postDepartmentMatchUrl}/get/all?postId=${payload.postId}&departmentId=${payload.departmentId}`)
  },
  //保存岗位评级和技能评价关系
  saveMatch(payload) {
    return axios.post(`${postDepartmentMatchUrl}/save/all`, payload)
  },
  //更新部门岗位匹配信息
  updateMatch(payload) {
    return axios.post(`${postDepartmentMatchUrl}/update/all`, payload)
  },
  //删除部门岗位匹配信息
  deleteMatch(payload) {
    return axios.post(`${postDepartmentMatchUrl}/delete/all?postId=${payload.postId}&departmentId=${payload.departmentId}`)
  },
  //获取企业模板列表接口
  getModelList() {
    return axios.get(`${postDepartmentMatchUrl}/model/list`)
  },
  //保存评级模板
  saveModel(payload) {
    return axios.post(`${postDepartmentMatchUrl}/save/model`, payload)
  },
  //获取模板详情
  getModelDetail(payload) {
    return axios.get(`${postDepartmentMatchUrl}/model/detail?modelId=${payload}`)
  },
  //获取部门岗位配置详情信息
  getConfigDetail(payload) {
    return axios.get(`${postDepartmentMatchUrl}/post/detail?postId=${payload}`)
  }
}
